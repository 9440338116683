import React from "react";
import { FaLinkedin, FaHandshake, FaEnvelope } from "react-icons/fa";
import gtag from "ga-gtag";

export default function Contact() {
  return (
    <section id="contact">
      <div className="container px-5 py-10 mx-auto">
        <div className="text-center mb-10">
          <FaHandshake className="w-10 text-3xl md:text-6xl inline-block mb-4" />
          <h1 className="sm:text-4xl text-3xl font-medium title-font text-white mb-4">
            Let's Connect
          </h1>
          <p className="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto">
            Let's get in touch and discuss how I can help you.
          </p>
        </div>
        <div className="flex flex-wrap max-w-md mx-auto justify-center text-xl mb-20">
          <a
            href="https://linkedin.com/in/m-nur-rizki"
            target={"_blank"}
            rel="noreferrer"
            onClick={() => {
              gtag("Contact", "Click", { media: "Linkedin" });
            }}
          >
            <FaLinkedin className="inline mr-2" /> Mohammad Nur Rizki
          </a>
          <a
            href="mailto:mnurrizki25@gmail.com"
            onClick={() => {
              gtag("Contact", "Click", { media: "Email" });
            }}
          >
            <FaEnvelope className="inline mr-2" /> mnurrizki25@gmail.com
          </a>
        </div>
      </div>
    </section>
  );
}
