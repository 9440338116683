import "./App.css";
import React, { useEffect } from "react";
import About from "./Components/About";
import Projects from "./Components/Projects";
import Skills from "./Components/Skills";
import Contact from "./Components/Contact";
import { CgSpinnerTwo } from "react-icons/cg";

function App() {
  useEffect(() => {
    setTimeout(() => {
      document.getElementById("loader").classList.add("hide");
    }, 100);
  }, []);
  return (
    <div className="text-gray-400 bg-gray-900 body-font scrollbar-hide">
      <div
        id="loader"
        className="flex flex-row text-gray-400 bg-gray-900 visible fixed z-50 h-screen w-screen mx-auto items-center place-content-center"
      >
        <div className="flex flex-col">
          <CgSpinnerTwo className="animate-spin mx-auto text-5xl mb-2" />
          <div className="mx-auto">Loading...</div>
        </div>
      </div>
      <About />
      <Projects />
      <Skills />
      <Contact />
    </div>
  );
}

export default App;
