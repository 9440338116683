import React from "react";
import { BsChevronDoubleDown } from "react-icons/bs";

export default function About() {
  return (
    <section id="about">
      <div className="container mx-auto px-10 py-20 h-screen items-center">
        <div className=" flex md:flex-row flex-col items-center h-full pb-20">
          <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
            <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-white">
              Hi, I'm Nuriz.
            </h1>
            <p className="mb-8 leading-relaxed text-xl">
              I love to make things. I'm a web developer with a passion
            </p>
            <div className="flex justify-center">
              <a
                href="#projects"
                className="inline-flex text-white bg-green-500 border-0 py-2 px-6 focus:outline-none hover:bg-green-600 rounded text-lg"
              >
                My Projects
              </a>
            </div>
          </div>
          <div className="lg:max-w-xl lg:w-full md:w-1/2 w-5/6">
            <img
              className="object-cover object-center rounded"
              alt="hero"
              src="./hero.png"
            />
          </div>
        </div>
        <div className="text-center flex items-center flex-col">
          <BsChevronDoubleDown className=" animate-bounce" />
          Scroll down to see my projects
        </div>
      </div>
    </section>
  );
}
